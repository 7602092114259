@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../.styles/placeholder-class";

.date-selector {
	width: 90vw;
	@include gridle_state(sm) {
		width: 250px;
	}

	&__actions {
		@extend %text-bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 14px 10px;
		border-bottom: 1px solid $input-border-color;

		svg {
			cursor: pointer;
		}
	}

	&__body {
		margin: 14px;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		width: 30px;
		height: 30px;
		margin-right: 5px;
		margin-bottom: 5px;
		cursor: pointer;
		background: white;
		color: $black;
		outline: 2px solid white;
		transition: all 50ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		font-size: 1.6rem;

		&:hover {
			@extend %text-bold;
		}

		@include gridle_state(sm) {
			width: 22px;
			height: 20px;
			font-size: 1.3rem;

			&:hover {
				font-size: 1.5rem;
			}
		}

		&--selected {
			outline: 2px solid $active-color;
			background: $active-color;
			color: white;

			&:hover {
				outline: 2px solid $active-color;
				background: $active-color;
				color: white;
				font-size: 1.5rem;
			}
		}
	}

	&__selector {
		display: none;
		flex-wrap: wrap;
		margin: 0 auto;

		&--visible {
			display: flex;
		}

		&--day {
			max-height: 215px;
			overflow: auto;
		}

		&--month {
			justify-content: center;

			.date-selector__item {
				width: 80px; // 80px pour que ca fasse 3 colonnes sur iphone 5
				height: 35px;

				@include gridle_state(sm) {
					width: 96px;
					height: 25px;
				}
			}
		}

		&--year {
			@extend %scrollbar-thin;
			padding: 2px; // necessaire pour que les contours de selection soit visibles
			max-height: 250px;
			overflow: auto;
			justify-content: center;

			.date-selector__item {
				width: 55px;
				height: 35px;

				@include gridle_state(sm) {
					width: 35px;
					height: 25px;
				}
			}
		}
	}

	&__error {
		padding: 0 10px;
		color: $color-error;
	}
}
