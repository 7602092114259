@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.popper {
	z-index: 200;

	&__overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&__body {
		background: white;
		border: 1px solid $grey-medium;
		padding: 20px;
		border-radius: $border-radius-big;
		box-shadow: 0 3px 6px #6c6c6c29;
	}

	&__arrow {
		display: block;
		z-index: -1;

		&--bottom {
			&:before {
				top: -9px;
			}

			&:after {
				top: -8px;
			}
		}

		&--top {
			bottom: 0px;

			&:before {
				transform: rotate(180deg);
				top: -4px;
			}

			&:after {
				transform: rotate(180deg);
				top: -5px;
			}
		}

		&--left {
			right: 10px;

			&:before {
				transform: rotate(90deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}

		&--right {
			left: 10px;

			&:before {
				transform: rotate(90deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}

		&:after {
			content: "";
			position: absolute;
			left: 1px;
			border-bottom: 14px solid #fff;
			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			border-bottom: 15px solid $grey-medium;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
		}
	}
}
