@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$input-border-radius: none !default;
$input-border: 1px solid $active-color !default;
$input-border-color: $grey-medium !default;
$input-shadow-box: none !default;

.advanced-select {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&__label {
		position: absolute;
		top: 15px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $black;
		pointer-events: none; // fix empeche de saisir sur device ios
	}

	.advanced-select__select {
		&__control {
			min-height: 50px;
			background: white;
			border: 1px solid $input-border-color !important;
			border-radius: $input-border-radius !important;
			box-shadow: $input-shadow-box;

			&--is-focused {
				border: $input-border !important;
				box-shadow: none !important;

				.sdp-search-form__suggestion-label {
					background: $input-focus-text-highligthed-color;
				}
			}

			&--menu-is-open {
				border: $input-border !important;
				box-shadow: none !important;
			}
		}

		&__input {
			margin-top: 0;
		}

		&__value-container {
			@extend %font-bold;
			padding-left: 13px; // 13 et pas 15 pour tenir compte des 2px de react-select__single-value
			padding-right: 13px; // 13 et pas 15 pour tenir compte des 2px de react-select__single-value
		}

		&__indicators {
			display: none;
		}

		&__menu-notice {
			text-align: left;
		}
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;
			top: 6px;
		}
	}

	&--required {
		#{$this}__label {
			&:after {
				content: "*";
			}
		}
	}

	&--error {
		#{$this}__select {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px rgba(197, 6, 18, 0.6);
			border: 1px solid $color-error;
			background: #fff;
		}

		#{$this}__label {
			@extend %font-bold;
			color: $color-error;
		}
	}

	&--with-icon {
		.advanced-select__select {
			&__value-container {
				padding-left: 47px;
			}
		}

		#{$this}__label {
			left: 50px;
			padding-left: 0;
		}

		#{$this}__icon {
			width: 18px;
			position: absolute;
			top: 0;
			left: 16px;
			bottom: 0;
			display: flex;
			align-items: center;
			pointer-events: none;

			svg {
				width: 18px;
			}
		}
	}

	// eviter qu'au chargement de la page, des blocs blanc sont visibles sur l’écran
	input {
		@extend %font-bold;
		background: transparent;
		border: none;
	}
}

:global(.advanced-select__select__menu-portal) {
	z-index: 999;
}

.advanced-select-menu {
	padding: 0;
	width: unset;
	background: white;
	border: 1px solid $grey-medium;
	border-radius: $border-radius-big;
	box-shadow: 0 3px 6px #6c6c6c29;

	// @see https://lugolabs.com/caret
	&:after {
		content: "";
		position: absolute;
		left: 30px;
		top: -9px;
		border-bottom: 9px solid #fff;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
	}

	&:before {
		content: "";
		position: absolute;
		left: 29px;
		top: -10px;
		border-bottom: 10px solid $grey-medium;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}
}

.advanced-select-menu-list {
	&__container {
		padding: 12px 10px;
	}
}

.advanced-select-option {
	background: none !important;
	height: 30px;
	cursor: pointer;

	&.advanced-select__select__option--is-selected {
		background: $grey-light !important;
		color: $black;

		.amount {
			&__prefix,
			&__value,
			&__currency {
				color: white;
			}
		}
	}

	@include gridle_state(md) {
		&.advanced-select__select__option--is-focused {
			background: $grey-light !important;
			color: $black;

			&.advanced-select__select__option--is-selected {
				background: $grey-light !important;
				color: $black;

				.amount {
					&__prefix,
					&__value,
					&__currency {
						color: white;
					}
				}
			}
		}
	}
}
