@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.date-picker-calendar {
	.week-days {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		height: 24px;
		box-sizing: border-box;
		border-top: 1px solid $grey-medium;
		border-bottom: 1px solid $grey-medium;
		padding-left: 10px;
		padding-right: 10px;

		&__item {
			text-align: center;
			width: 50px;
			font-size: 1.1rem;
			color: $grey-dark;
		}
	}

	.datepicker-calendar-list-item {
		padding-top: 20px;
	}

	.datepicker-calendar-month {
		border-bottom: 1px solid $grey-light;
	}
}

.date-picker-calendar-tile {
	padding-right: 10px;
	padding-left: 10px;

	&__tile {
		@extend %font-regular;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		height: 60px;
		border: none;
		background: none;
		color: $black;
		font-size: 1.4rem;
		box-sizing: border-box;
		width: 50px;

		&--disabled {
			pointer-events: none;
			color: $grey-medium;
		}
	}
}

.react-calendar__tile--range {
	&.react-calendar__tile--hasActive {
		abbr {
			width: 45px;
			color: white;
			background: $active-color;
			border-radius: 50%;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
