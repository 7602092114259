@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../form/Input";

.date-selector-input {
	&__date-selector {
		position: relative;
	}

	.control-group {
		&__input {
			padding-top: 5px; // pour separer un peu plus la date et le libellé
		}

		&--required {
			.control-group__label {
				&::after {
					content: "*";
				}
			}
		}
	}

	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer;

		.icon {
			width: 15px;
			height: 15px;
		}
	}

	&__popper {
		.popper__body {
			padding: 0;
			margin: 0 15px;
		}
	}

	&__popover {
		z-index: 6; // for the date selector to be above the product aside column

		.Popover-tip {
			fill: $active-color;
		}

		.Popover-body {
			background: white;
			border: 1px solid $grey-medium;
		}
	}
}
