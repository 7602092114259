@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.drawer {
	height: 100vh;
	width: 100vw;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	background: white;

	&__header {
		height: 60px;
		background: red;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include gridle_state(md) {
			height: 120px;
		}
	}
}
